<template>
  <div id="products" class="products">
    <div
      v-for="(product, index) in $store.state.products"
      :ref="'menu' + index"
      class="product"
      :id="'p' + product.id"
      :key="product.id"
    >
      <router-link
        :to="{
          name: 'SingleP',
          params: { title: product.title, id: product.id },
        }"
      >
        <div class="frame">
          <img
            class="image"
            :src="product.ProductImage"
            :alt="'LRP ' + product.title"
          />
        </div>
        <div class="textp">
          <h1 class="productTitle">{{ product.title }}</h1>
          <p v-html="product.content"></p>
        </div>
        <div class="fade"></div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ["idp"],
};
</script>

<style scoped>
.products {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.product {
  background-color: white;
  text-decoration: none;
  border-radius: 4px;
  transition-duration: 0.3s;
  min-width: 20%;
  max-width: 20%;
  overflow: hidden;
  margin: 2% 1% 4% 1%;

  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.04),
    0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(0, 0, 0, 0.08);
}

.product:hover {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2), 0 0 10px 0 rgba(0, 0, 0, 0.15);
}

.textp {
  text-align: left;
  color: #2c3e50;
  font-size: 1.2rem;
  padding: 8% 8% 0 8%;
}



.product a {
  text-decoration: none;
}

.image {
  width: 100%;
  height: auto;
}

.frame {
  display: flex;
  justify-content: center;
  overflow: hidden;
}
</style>