<template>
  <div id="products" class="products">
    <div
      v-for="(product, index) in $store.state.products"
      :ref="'menu' + index"
      :class="'product b' + index"
      :key="index"
    >
      <router-link
        :to="{
          name: 'SingleP',
          params: {
            title: product.title,
            id: product.id,
            lang: $store.state.lang
          },
        }"
      >
        <img class="image" :src="product.ProductImage" alt="" />
        <div class="textp">
          <h1 class="productTitle">{{ product.title }}</h1>
          <p v-html="product.content"></p>
        </div>
        <div class="fade"></div>
      </router-link>
    </div>
    <div class="arrows">
      <div @click="scrollLeft" class="arrowLeft"></div>
      <div @click="scrollRight" class="arrowRight"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      products: [],
    };
  },
  mounted() {},

  methods: {
    scrollRight() {
      document.getElementById("products").scrollBy(600, 0);
    },
    scrollLeft() {
      document.getElementById("products").scrollBy(-600, 0);
    },
  },
};
</script>

<style>
.arrows {
  position: absolute;
  display: inline-flex;
  justify-content: space-between;
  width: 94.3%;
  left: 2.9%;
  top: 47.5%;
}


.arrowLeft {
  transform: rotate(180deg);
  height: 5vh;
  width: 5vh;
  background-image: url(../assets/arrow.svg);
  cursor: pointer;
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.3));
}

.arrowRight {
  height: 5vh;
  width: 5vh;
  background-image: url(../assets/arrow.svg);
  cursor: pointer;
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.3));
}

.products {
  display: inline-flex;
  width: 98%;
  overflow: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
}


.products::-webkit-scrollbar {
  display: none;
}

.product {
  background-color: white;
  text-decoration: none;
  border-radius: 2px;
  transition-duration: 0.3s;
  min-width: 18%;
  overflow: hidden;
  margin: 3% 1% 3% 1%;
  scroll-snap-align: center;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.04),
    0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(0, 0, 0, 0.08);
}

.product:hover {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2), 0 0 10px 0 rgba(0, 0, 0, 0.15);
}

.textp {
  text-align: left;
  color: #2c3e50;
  font-size: 1.2rem;
  padding: 2% 8% 0 8%;
  height: 15vh;
}

.productTitle {
  font-size: 1.4rem;
  font-weight: 900;
  margin: 1vh 0 -1vh 0;
}

.fade {
  background: linear-gradient(
    0deg,
    rgb(255, 255, 255) 40%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 100%;
  height: 6vh;
  position: relative;
  bottom: -3px;
}

.product a {
  text-decoration: none;
}

.image {
  width: 100%;
}
</style>