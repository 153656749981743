<template>
  <div class="wrapper parallax">
    <transition name="fade">
      <Banner v-if="$store.state.bbtn && $store.state.homesfetched" />
    </transition>
    <div v-if="$store.state.fetched" class="home" id="home">
      <MenuBlocks />
    </div>
    <div class="space" v-if="$store.state.bbtn"></div>
  </div>
</template>

<script>
import MenuBlocks from "../components/MenuBlock";
import Banner from "../components/Banner.vue";

export default {
  name: "Home",
  components: {
    MenuBlocks,
    Banner,
  },

  mounted () {
    this.scrollToPos()
  },

  beforeUnmount() {
    this.scrollPosition();
  },

  unmounted() {
    this.$store.state.bbtn = false;
  },

  methods: {
    scrollPosition() {
      this.$store.state.scrollposition =
        document.querySelector(".wrapper").scrollTop;
    },
    scrollToPos() {
      document.querySelector(".wrapper").scrollTo( 0, this.$store.state.scrollposition );
    }

  },
};
</script>

<style>
.wrapper {
  height: fit-content;
}

.space {
  display: block;
  content: "";
  height: 100vh;
  width: 100%;
}

.parallax {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  perspective: 1px;
  -webkit-perspective: 1px;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}
.home {
  display: flex;
  position: sticky;
  top: 0;
  padding-top: 20vh;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}

.home::after {
  background-image: url("../assets/DNA_human_large_full.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transform-origin: -80% 30%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -3;
  content: "";
  transform: translateZ(-1px) scale(1.5);
  -webkit-transform: translateZ(-1px) scale(1.5);
}

.fade-enter-active {
  transition: transform 0.5s;
}
.fade-leave-active {
  transition: all 0.3s ease-out;
}
.fade-leave-to {
  transform: translateY(-100vh);
}
</style>