<template>
  <MenuBar />
  <div class="center">
    <router-view />
  </div>
</template>

<script>
import MenuBar from "./components/MenuBar";

export default {
  components: {
    MenuBar,
  },

  async mounted() {
    this.$store.state.lang = navigator.language;
    const html = document.documentElement;
    html.setAttribute("lang", this.$store.state.lang);

    await this.$store.dispatch("getHomes");
    this.$store.state.homesfetched = true;

    await this.$store.dispatch("getData");
    this.$store.state.fetched = true;

    await this.$store.dispatch("getProducts");
    this.$store.state.productsfetched = true;
  },

  watch: {
    $route: function () {
      if (this.$route.params.title) {
        document.title = "LRP | " + this.$route.params.title;
      }
      // if ( this.$route.params.title !='Home' ) {
      //   window.scrollTo(0, 0);
      //         console.log("scrolled");

      //   }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1.6;
}

.content h2 {
  font-weight: 900;
}

.text h2 {
  font-weight: 900;
}

.center {
  display: flex;
  justify-content: center;
}

a {
  text-decoration: none;
  color: #2c3e50;
}

td {
  padding: 3% 3% 3% 3%;
}

:root {
  font-size: 1.4vh;
}

.routetrans-enter-active,
.routetrans-leave-active {
  transition: opacity 0.3s ease;
}

.routetrans-enter-from,
.routetrans-leave-to {
  opacity: 0;
}

.routetrans-move {
  opacity: 1;
}

img.size-full {
  width: 100%;
  height: auto;
}

.text img.size-full {
  display: none !important;
}

.text [class^="wp-block"] {
  display: inherit;
}

.text div .wp-block-media-text__content {
  padding: 0;
}

.text div .wp-block-column {
  margin: 0;
}

.circle {
  mask-image: radial-gradient(
    circle at 50% 50%,
    black 49.7%,
    rgba(255, 255, 255, 0) 50%
  );
}
</style>
