<template>
  <div v-if="getSingleP" class="single" id="single">
    <h1 class="title">{{ getSingleP.title}}</h1>
    <div class="singleC">
      <div v-html="getSingleP.content" class="content"></div>
    </div>
  </div>




</template>

<script>
export default {
  props: [ 'id' ],

  computed: {
    getSingleP() {
      return this.$store.state.products.find(
        (product) => product.id == this.id
      );
    },
  },

}

</script>

<style>

</style>