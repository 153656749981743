import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Single from '../views/Single.vue'
import SingleP from '../views/SingleP.vue'

const routes = [


  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: '/:id/:title',
    name: 'Single',
    component: Single,
    props: true,
  },

  {
    path: '/products/:id/',
    name: 'SingleP',
    component: SingleP,
    props: true,
  },

  {
    path: '/:catchAll(.*)',
    redirect: '/',
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { left: 0, top: 0 }
    }
  }
})


export default router
