import { createStore } from 'vuex'

export default createStore({
  state: {
    homes: [],
    homes_all: [],
    menublocks_all: [],
    menublocks: [],
    products: [],
    products_all: [],
    lang: '',
    bbtn: true,
    fetched: false,
    homesfetched: false,
    productsfetched: false,
    scrollposition: ''
  },

  getters: {
    getMenublocksDE(state) {
      return state.menublocks_all.map((menublock) => {
        return {
          id: menublock.id,
          title: menublock.title.rendered,
          title_en: menublock.acf.translation_en.post_title,
          content: menublock.content.rendered,
          menublock_bild: menublock.acf.menublock_bild.url
        };
      })
    },
    getMenublocksEN(state) {
      return state.menublocks_all.map((menublock) => {
        return {
          id: menublock.id,
          title: menublock.acf.translation_en.post_title,
          title_en: menublock.acf.translation_en.post_title,
          content: menublock.acf.translation_en.post_content,
          menublock_bild: menublock.acf.menublock_bild.url

        };
      })
    },
    getMenublocksFR(state) {
      return state.menublocks_all.map((menublock) => {
        return {
          id: menublock.id,
          title: menublock.acf.translation_fr.post_title,
          title_en: menublock.acf.translation_en.post_title,
          content: menublock.acf.translation_fr.post_content,
          menublock_bild: menublock.acf.menublock_bild.url
        };
      })
    },

    getProductsDE(state) {
      return state.products_all.map((product) => {
        return {
          id: product.id,
          title: product.title.rendered,
          content: product.content.rendered,
          ProductImage: product.acf.ProductImage.url
        };
      })
    },
    getProductsEN(state) {
      return state.products_all.map((product) => {
        return {
          id: product.id,
          title: product.acf.translation_en.post_title,
          content: product.acf.translation_en.post_content,
          ProductImage: product.acf.ProductImage.url
        };
      })
    },
    getProductsFR(state) {
      return state.products_all.map((product) => {
        return {
          id: product.id,
          title: product.acf.translation_fr.post_title,
          content: product.acf.translation_fr.post_content,
          ProductImage: product.acf.ProductImage.url
        };
      })
    },

    getHomesDE(state) {
      return state.homes_all.map((home) => {
        return {
          id: home.id,
          title: home.acf.title,
          content: home.acf.content,
          backgroundImage: home.acf.backgroundImage.url,
        };
      })
    },
    getHomesEN(state) {
      return state.homes_all.map((home) => {
        return {
          id: home.id,
          title: home.acf.title_en,
          content: home.acf.content_en,
          backgroundImage: home.acf.backgroundImage.url,
        };
      })
    },
    getHomesFR(state) {
      return state.homes_all.map((home) => {
        return {
          id: home.id,
          title: home.acf.title_fr,
          content: home.acf.content_fr,
          backgroundImage: home.acf.backgroundImage.url,
        };
      })
    },


  },


  actions: {


    async getHomes() {

      await fetch("https://lrp.swiss/lrpwebsite/wp-json/wp/v2/homes")
        .then((res) => res.json())
        .then((data) => (this.state.homes_all = data))
        .catch((err) => console.log(err.message));

      if (this.state.lang.includes('en')) {
        this.dispatch('en')
      } else if (this.state.lang.includes('fr')) {
        this.dispatch('fr')
      } else {
        this.dispatch('de')
      }
    },

    async getData() {

      await fetch("https://lrp.swiss/lrpwebsite/wp-json/wp/v2/menublock_de")
        .then((res) => res.json())
        .then((data) => (this.state.menublocks_all = data))
        .catch((err) => console.log(err.message));


      if (this.state.lang.includes('en')) {
        this.dispatch('en')
      } else if (this.state.lang.includes('fr')) {
        this.dispatch('fr')
      } else {
        this.dispatch('de')
      }
    },

    async getProducts() {

      await fetch("https://lrp.swiss/lrpwebsite/wp-json/wp/v2/product")
        .then((res) => res.json())
        .then((data) => (this.state.products_all = data))
        .catch((err) => console.log(err.message));

      if (this.state.lang.includes('en')) {
        this.dispatch('en')
      } else if (this.state.lang.includes('fr')) {
        this.dispatch('fr')
      } else {
        this.dispatch('de')
      }
    },


    de({ getters }) {
      this.state.menublocks = getters.getMenublocksDE;
      this.state.products = getters.getProductsDE;
      this.state.homes = getters.getHomesDE;
      this.state.lang = 'de'
    },

    en({ getters }) {
      this.state.menublocks = getters.getMenublocksEN;
      this.state.products = getters.getProductsEN;
      this.state.homes = getters.getHomesEN;
      this.state.lang = 'en'

    },

    fr({ getters }) {
      this.state.menublocks = getters.getMenublocksFR;
      this.state.products = getters.getProductsFR;
      this.state.homes = getters.getHomesFR;
      this.state.lang = 'fr'
    },
  },
  modules: {
  }
})
