<template>
  <div v-if="getSingle" class="single" id="single">
    <h1 class="title">{{ getSingle.title }}</h1>
    <div id="singleC" class="singleC">
      <div v-html="getSingle.content" class="content"></div>
    </div>
    <ProductsAll v-if="getSingle.title_en == 'Products'" />
  </div>
</template>

<script>
import ProductsAll from "../components/ProductsAll.vue";

export default {
  props: ["id", "title"],
  components: {
    ProductsAll,
  },

  mounted() {


    const child = document.getElementsByClassName("size-full");
    const source = document.getElementsByClassName("circle");

      Array.from(child).forEach(function(element) {
            element.setAttribute('width', '100%')
            element.setAttribute('height', 'auto')
        });

           Array.from(source).forEach(function(element) {
             element.setAttribute('border-radius', '50%')
            element.setAttribute('width', '100%')
            element.setAttribute('height', 'auto')
            
        });
    
  },


  computed: {
    getSingle() {
      return this.$store.state.menublocks.find(
        (menublock) => menublock.id == this.id
      );
    },
  },
};
</script>


<style>

@import 'https://lrp.swiss/lrpwebsite/wp-includes/css/dist/block-library/style.min.css';

.fadein-enter-active,
.fadein-leave-active {
  transition: 3s ease;
  opacity: 1;
}

.fadein-enter-from,
.fadein-leave-to {
  opacity: 0;
}

.fadein-move {
  transition: 3s ease;
}

.single {
  position: relative;
  width: 80%;
  max-width: 1728px;
  margin-top: 20vh;
  margin-bottom: 10vh;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 1vw 1vw 0 rgba(0, 0, 0, 0.16), 0 0 1vw 0 rgba(0, 0, 0, 0.08);
}

.title {
  position: absolute;
  font-size: 5vh;
  background-color: #eb4147;
  color: white;
  margin: -4vh 0 0 7vh;
  width: fit-content;
  box-shadow: 0 1vw 1vw 0 rgba(0, 0, 0, 0.16), 0 0 1vw 0 rgba(0, 0, 0, 0.08);
  padding: 0 2% 0 2%;
}

.singleC {
  padding: 6vh 7% 2% 7%;
  display: flex;
  text-align: left;
  color: #2c3e50;
  font-size: 1.25rem;
}

.wp-block-image img {
  height: auto;
}



.content {
  max-width: 100%;
}


.content ul li {
  list-style-type: none;
    background-image: url('../assets/bpoint.svg');
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: 0 8px;
    padding-left: 40px;
    line-height: 2.5;
}

.textp ul li {
  list-style-type: none;
    background-image: url('../assets/bpoint.svg');
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: 0 8px;
    padding-left: 40px;
    line-height: 2.5;
}


</style>

