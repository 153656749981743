<template>
  <div class="btn">
      <img src="../assets/menubtn.svg" alt="">
  </div>
</template>

<script>
export default {
    props: ["menublocks"],

}
</script>

<style>

.btn img {
 height: 100%;
}

.btn {
    height: 60%;
}


</style>