<template>
  <div class="MenuBar">
    <router-link
      class="logo"
      :to="{
        name: 'Home',
        params: { title: 'Home', lang: $store.state.lang },
      }"
    >
      <img src="../assets/LRP-Logo_CMYK.svg" alt="LRP" />
    </router-link>
    <ul
      @click="pbtn = !pbtn"
      class="menu"
      :class="{ active: pbtn, notactive: !pbtn }"
    >
      <li v-for="menublock in $store.state.menublocks" :key="menublock.id">
        <router-link
          :to="{
            name: 'Single',
            params: {
              id: menublock.id,
              title: menublock.title,
              lang: $store.state.lang
            },
          }"
        >
          <div class="dot" />
          <div>{{ menublock.title }}</div>
        </router-link>
      </li>
    </ul>
    <DropBar @click="pbtn = !pbtn" :class="{ turn: !pbtn }" />

    <div class="lang">
      <div @click="de">DE |</div>
      <div @click="fr">FR |</div>
      <div @click="en">EN</div>
    </div>
  </div>
</template>

<script>
import DropBar from "../components/DropBar";

export default {
  components: {
    DropBar,
  },

  data() {
    return {
      pbtn: true,
      isActive: true,
    };
  },

  methods: {
    de() {
      this.$store.dispatch("de");
    },

    fr() {
      this.$store.dispatch("fr");
    },

    en() {
      this.$store.dispatch("en");
    },
  },
};
</script>

<style>
.logo {
  height: 60%;
  margin-left: 2vh;
}

.logo img {
  height: 100%;
}

.menu {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 0;
  padding: 0;
}

.menu li {
  list-style-type: none;
  white-space: nowrap;
}

.menu a:hover {
  color: #ed1c24;
}

.menu a.router-link-active .dot {
  background-color: #ed1c24;
  transition-duration: 0.5s;
}

.dot {
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  margin-bottom: 0.1rem;
  border-radius: 100%;
  background-color: white;
}

.menu a {
  font-size: 1.3rem;
  text-decoration: none;
  color: #2c3e50;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
}

.lang {
  font-size: 1rem;
  padding: 0.5rem 0 0.5rem 0;
  white-space: nowrap;
  margin: 0 2% 0 2%;
}

.lang div {
  display: inline;
  cursor: pointer;
}

.MenuBar {
  position: fixed;
  display: flex;
  width: 100%;
  height: 7vh;
  top: 0;
  z-index: 2;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16), 0 0 8px 0 rgba(0, 0, 0, 0.08);
  /* border-bottom:solid 1px #6D6E71; */
}

.btn {
  display: none;
}

@media all and (max-width: 1023px) {
  .menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: white;
    height: fit-content;
    width: 100%;
    align-self: flex-start;
    padding: 9vh 0 0 0;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16), 0 0 8px 0 rgba(0, 0, 0, 0.08);
    z-index: -1;
  }
  .bbContent {
    font-size: 1.2rem !important;
  }

  .active {
    transform: translateY(-103%);
    transition-duration: 0.2s;
  }
  .notactive {
    transform: translateY(0);
    transition-duration: 0.2s;
  }

  .btn {
    display: block;
    position: fixed;
    height: 5vh;
    left: 49%;
  }

  .turn {
    transform: rotate(90deg);
    transition-duration: 0.2s;
  }

  .product {
    min-width: 25% !important;
  }

  .menublock {
    max-width: 85% !important;
    margin: 8vh 0 0 0 !important;
  }

  .text h1 {
    font-size: 3.4vh !important;
    margin-top: -7vh !important;
  }

  .blocktitle {
    font-size: 3.4vh !important;
  }

  .foregroundT {
    flex-direction: column;
  }

  .menu a {
    padding: 3vh 0 3vh 0;
  }

  .MenuBar {
    height: 10vh;
  }
}

@media all and (max-width: 700px) {
  .bannerContent > p::before {
    margin-left: 0!important;;
  }

  .product {
    min-width: 50% !important;
    flex-direction: column !important;
  }

  .title {
    font-size: 3vh !important;
  }

  .text {
    font-size: 1.5rem !important;
  }

  .singleC {
    font-size: 1.5rem !important;
  }

  .content ul li {
    line-height: 3 !important;
  }

  .home::after {
    display: none;
  }

  .image {
    width: 100% !important;
  }

  .products {
    padding: 0 0 5% 0 !important;
  }

  .single {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .menublock {
    max-width: 100% !important;
  }

  td {
    display: flex;
    flex-direction: column;
    width: 100% !important;
  }
}
</style>