<template>
  <div
    v-for="(menublock, index) in $store.state.menublocks"
    :ref="'menu' + index"
    :class="'menublock ' + menublock.title_en"
    :key="index"
  >
    <router-link
      v-if="menublock.title"
      :to="{
        name: 'Single',
        params: {
          title: menublock.title,
          id: menublock.id,
          lang: $store.state.lang
        },
      }"
    >
      <h1 class="blocktitle">{{ menublock.title }}</h1>
      <div v-if="menublock.title_en != 'Products' ">
        <img v-if="menublock.menublock_bild"
          class="image"
          :src="menublock.menublock_bild"
          alt=""
        />
      </div>
      <div v-if="menublock.title_en !== 'Products'" class="block" >
        <div class="text">
          <p v-html="menublock.content"></p>
        </div>
      </div>
      <div v-if="menublock.title_en !== 'Products' && menublock.title_en !== 'Contact'" class="fade"></div>
    </router-link>
    <Products v-if="menublock.title_en == 'Products'" />
  </div>
</template>

<script>
import Products from "../components/Products.vue";

export default {
  components: {
    Products,
  },
};
</script>

<style scoped>
.menublock {
  background-color: white;
  text-decoration: none;
  margin: 7vh 0 7vh 0;
  box-shadow: 0 2vw 2vw 0 rgba(0, 0, 0, 0.16), 0 0 2vw 0 rgba(0, 0, 0, 0.08);
  min-width: 20%;
  max-width: 27%;
  flex-shrink: 1;
}

.block {
  height: 21vh;
  padding: 1vh 2vw 0 2vw;
  overflow: hidden;
}

.fade {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 30%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 100%;
  height: 4vh;
  transform: translateY(-100%);
}

.menublock:hover {
  transform: scale(1.001);
  box-shadow: 0 2.1vw 2.1vw 0 rgba(0, 0, 0, 0.16),
    0 0 2.1vw 0 rgba(0, 0, 0, 0.08);
  transition-duration: 0.2s;
}

.fade-enter-from {
  opacity: 0;
}

.fade-enter-active {
  transition: 3s ease;
}

.text {
  width: 100%;
  position: relative;
  text-align: left;
  color: #2c3e50;
  font-size: 1.2rem;
}


.blocktitle {
  position: absolute;
  font-size: 1.7vw;
  background-color: #eb4147;
  color: white;
  width: fit-content;
  box-shadow: 0 1vw 1vw 0 rgba(0, 0, 0, 0.16), 0 0 1vw 0 rgba(0, 0, 0, 0.08);
  margin: 0;
  padding: 0 1vw 0 1vw;
  transform: translateY(-50%);
  white-space: nowrap;
  z-index: 1;
  
}


.menublock a {

  text-decoration: none;
}

a {
  text-decoration: none;
  color: #eb4147;
}
a:link {
  text-decoration: none;
  color: #eb4147;
}
a:visited {
  text-decoration: none;
  color: #eb4147;
}

.Products {
  max-width: 87%;
  flex-grow: 1;
  left: 0;
  position: relative;
  padding-left: 1.5%;
  padding-right: 1.5%;
}

.Contact .block{
  height: fit-content;
  white-space: nowrap;

}



.image {
  max-width: 100%;
  background-color: transparent;
}
</style>